@import '@/styles/breakpoints.module';

.contactsSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--footer-border-color);

  & > :first-child:not(:last-child) {
    flex-shrink: 0;
  }

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }
  @include desktop {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
}

.emailRow {
  margin-bottom: 12px;
  height: inherit;

  &:last-child {
    margin-bottom: 0;
  }
}

.supportBtn {
  color: var(--palette-white) !important;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none !important;
  }

  @include tablet {
    width: fit-content;
  }
  @include desktop {
    margin-bottom: 24px;
  }
}
