@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.disclaimer {
  @include typography.bodyXS_;
  @include tablet {
    @include typography.bodyS_;
  }
  color: var(--palette-gray-200);
}
