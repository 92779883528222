@use '@/styles/breakpoints.module';

.container {
  --padding-container: 16px;
  @include breakpoints.tablet {
    --padding-container: 40px;
  }
  padding: 0 var(--padding-container) 0 var(--padding-container);
  max-width: 1140px;
  margin-inline: auto;
  box-sizing: content-box;
}

.footer {
}

.header.header {
  max-width: 1280px;
  --padding-container: 16px;
  @include breakpoints.desktop {
    --padding-container: 20px;
  }
}
