@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.tab.tab {
  @include bodyM_;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-tab-text);
  border-bottom: 4px solid var(--color-tab-border);
  font-weight: var(--font-weight-btn);
  width: 100%;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: var(--color-tab-hover-text);
  }

  svg,
  img {
    width: 24px;
    height: 24px;
    margin-inline-end: 12px;
  }

  &.tabActive {
    color: var(--color-tab-active-text);
    border-bottom: 4px solid var(--color-tab-active-border);
  }
}

.tabFilled.tabFilled {
  padding: 8px 12px;

  svg,
  img {
    width: 16px;
    height: 16px;
    margin-inline-end: 4px;

    @include tablet {
      width: 24px;
      height: 24px;
      margin-inline-end: 12px;
    }
  }

  @include bodyM_;
  @include tablet {
    @include h5Tablet_;
    padding: 24px 12px;
  }
  @include desktop {
    padding: 24px 12px;
  }

  &.tabActive {
    background: linear-gradient(180deg, rgba(41, 85, 255, 0) 0%, rgba(41, 85, 255, 0.07) 100%);
  }
}

.tabsContainer {
  overflow: auto;
  display: flex;
  justify-content: space-between;
}

.tabsOverflowContainer {
  padding: 0;
  padding-inline-start: 12px;
  max-width: 1140px;
  margin: 0 auto;
  box-sizing: content-box;

  @include tablet {
    padding: 0 var(--container-padding);
  }
}

.tabPanel {
  display: block;

  &[hidden] {
    display: none;
  }
}
