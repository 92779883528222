@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.sectionsGroup {
  display: grid;
  margin-top: 40px;
  margin-bottom: 40px;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  @include tablet {
    grid-gap: 48px;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @include desktop {
    grid-gap: 60px;
    margin-top: 60px;
    margin-bottom: 100px;
  }
}

.mainCard {
  padding: 16px;
  @include tablet {
    padding: 24px 24px 80px;
    flex-shrink: 1.5;
  }
  @include tablet {
    padding: 24px 8px 40px 8px;
    max-width: 750px;
    width: 100%;
    & > div {
      max-width: 702px;
      width: 100%;
      margin: 0 auto;
    }
  }
}

.mainCardInner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  @include tablet {
    gap: 48px;
  }
}

.buySellWidgetWrapper {
  display: none;
  @include desktop {
    display: initial;
  }
}

.summary {
  b {
    font-weight: var(--palette-font-weight-semibold);
  }
}
