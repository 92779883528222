$tablet: 768px;
$desktop: 992px;
$large-desk: 1200px;

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin large-desk {
  @media screen and (min-width: $large-desk) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}
