@import '@/styles/breakpoints.module';

.aboutTab {
  & > :first-child {
    margin-bottom: 16px;
    @include tablet {
      margin-bottom: 24px;
    }
  }
}

.statsTabContent {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .changeIntervals {
    padding: 16px;
    background-color: var(--palette-gray-50);
    border-radius: var(--palette-border-radius-s);

    table {
      td,
      th {
        padding: 16px 12px;
        &:first-child {
          text-align: start;
        }
        &:last-child {
          text-align: end;
        }
      }

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid var(--palette-gray-125);
        }
      }
    }
  }

  .statsTabContentWidget {
    height: 418px !important;
    min-height: 418px !important;
    flex-grow: 1;
    border-radius: var(--palette-border-radius-s);
    border: 1px solid var(--palette-gray-125);
  }
}

.coinTabsSection {
  :global .MuiTabPanel-root {
    padding: 0px;
  }
}
//.statisticsGrid {
//  display: grid;
//  grid-template-columns: 1fr 1fr;
//  row-gap: 16px;
//
//  @include tablet {
//    grid-template-columns: 1fr 1fr 1fr;
//  }
//
//  & > * {
//    border-bottom: 1px solid var(--palette-gray-100);
//    display: flex;
//    flex-direction: column;
//    gap: 8px;
//    padding-bottom: 16px;
//    & > :first-child {
//      color: var(--palette-gray-500);
//    }
//    & > :last-child {
//      color: var(--color-text-primary);
//      font-weight: var(--palette-font-weight-semibold);
//    }
//    &:last-child {
//      grid-column: span 2;
//      @include tablet {
//        grid-column: auto;
//      }
//    }
//  }
//}
