@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.notChangingColorOnRowHover {
  display: block;
}

.datagrid {
  background-color: var(--color-table-row-bg);
  border-radius: var(--border-radius-table);
  border: none;
  overflow: hidden;

  :global .MuiDataGrid-columnSeparator {
    display: none;
  }

  :global .MuiDataGrid-columnHeaders {
    border: none;
  }

  :global .MuiDataGrid-cell,
  :global .MuiDataGrid-columnHeader {
    border: none;
    padding-inline: var(--padding-table-cell);

    &:focus,
    &:focus-within {
      outline: none;
    }

    &:first-child {
      padding-inline: var(--padding-table-cell-first);
    }

    &:nth-last-child(1),
    &:nth-last-child(1 of [role='cell']) {
      padding-inline: var(--padding-table-cell-last);
    }

    &:global(.MuiDataGrid-cell) {
      @include typography.bodyM_;
      font-weight: var(--font-weight-table-cell);
      color: var(--color-table-cell-text);

      &:last-child:not([role='cell']) {
        display: none;
      }
    }

    &:global(.MuiDataGrid-columnHeader) {
      @include typography.bodyM_;
      font-weight: var(--font-weight-table-cell);
      user-select: none;
      color: var(--color-table-heading-text);

      :global .MuiDataGrid-columnHeaderTitle {
        white-space: normal;
      }

      &:global(.MuiDataGrid-columnHeader--alignRight) {
        :global .MuiDataGrid-columnHeaderTitleContainer {
          flex-direction: row;
          justify-content: flex-end;
        }
      }

      &:hover :global .MuiDataGrid-iconButtonContainer svg {
        color: var(--palette-white);
      }

      :global .MuiDataGrid-iconButtonContainer {
        width: 26px;
        height: 26px;

        button {
          visibility: visible;
        }

        svg {
          width: 16px;
          height: 16px;
          color: var(--color-table-heading-text);
          transition: color 100ms ease-in-out;
        }
      }
    }
  }

  :global .MuiDataGrid-row {
    cursor: pointer;

    &:nth-child(odd) {
      background-color: var(--color-table-stripe-row-bg);
    }
  }

  :global(.MuiDataGrid-row):hover {
    background-color: var(--color-table-row-hover-bg);

    :global .MuiDataGrid-cell {
      color: var(--color-table-hover-cell-text);
      --color-text-secondary: var(--color-table-hover-cell-text);
    }

    // bad piece begin
    svg {
      filter: grayscale(1) brightness(10);
    }

    .notChangingColorOnRowHover svg {
      filter: initial;
    }

    // bad piece end

    --color-btn-outline-bg: transparent;
    --color-btn-outline-border: var(--palette-white);
    --color-btn-outline-text: var(--palette-white);
    --color-btn-outline-hover-bg: var(--palette-white);
    --color-btn-outline-hover-border: var(--palette-white);
    --color-btn-outline-hover-text: var(--palette-primary-700);
    --color-trend-negative: white;
    --color-trend-positive: white;
  }
}

.scrollMargin {
  scroll-margin: calc(var(--height-header) + var(--height-second-nav-row) + 16px);
}

.priceAndChangeHeader {
  :global(.MuiDataGrid-columnHeaderTitleContainer) {
    justify-content: flex-end;

    @include tablet {
      justify-content: flex-start;
    }
  }
}
