@import '@/styles/breakpoints.module';
@import '@/blocks/components/Container/styles.module';

.sectionsGroup {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  padding-top: var(--section-padding-sm);
  padding-bottom: var(--section-padding-sm);
  border-radius: var(--border-radius-cta-block-container);

  @include tablet {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
    padding-top: var(--section-padding-md);
    padding-bottom: var(--section-padding-md);
  }

  @include desktop {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
    padding-top: var(--section-padding-lg);
    padding-bottom: var(--section-padding-lg);
  }

  // TODO another solution
  & *[class*='_container_'] {
    padding-inline-end: 12px;
    padding-inline-start: 12px;
    @include tablet {
      padding-inline-end: 40px;
      padding-inline-start: 40px;
    }
  }
}

.gap {
  & > * + * {
    margin-top: 80px;
    @include tablet {
      margin-top: 96px;
    }
    @include desktop {
      margin-top: 160px;
    }
  }
}
