@import '@/styles/breakpoints.module';

.bottomLgLogoBlock {
  text-align: center;

  @include tablet {
    padding: 0 7%;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 146px;
    max-width: 996px;
  }
}
