@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.stepsList {
  --step-list-gap: 16px;
  --sep-marker-gap: 12px;
  --step-marker-size: 40px;
  --step-marker-padding: 8px;
  @include tablet {
    --step-list-gap: 24px;
    --sep-marker-gap: 16px;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--step-list-gap);

  &.separated {
    gap: var(--step-list-gap);

    & > *:not(:last-child) {
      border-bottom: 1px solid var(--palette-gray-100);
      padding-bottom: var(--step-list-gap);
    }
  }
}

.step {
  display: flex;
  align-items: baseline;
  gap: var(--sep-marker-gap);

  .marker {
    @include typography.bodyM_;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: var(--step-marker-size);
    height: var(--step-marker-size);
    padding: var(--step-marker-padding);

    font-weight: var(--font-weight-heading);
    background-color: var(--color-step-square-bg);
    color: var(--color-step-square);
    border-radius: var(--border-radius-step-square);
  }

  .stepBody {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @include tablet {
      gap: 8px;
    }
  }

  .stepStandaloneTitle {
    display: flex;
    align-items: center;
  }
}
