@import '@/styles/breakpoints.module';

.carouselsBlock {
  --gradient-direction: to right;
  --gradient-multiplicator: -1;
  --shadow-width: 80px;

  [dir='rtl'] & {
    --gradient-direction: to left;
    --gradient-multiplicator: 1;
  }

  position: relative;

  &::before,
  &::after {
    background: linear-gradient(var(--gradient-direction), rgba(0, 0, 0, 1), rgba(15, 15, 15, 0));
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    line-height: 0;
    width: var(--shadow-width);
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    inset-inline-start: 0;
  }

  &::after {
    inset-inline-end: 0;
    transform: rotate(180deg);
  }

  @include tablet {
    --shadow-width: 235px;
  }
  @include desktop {
    --shadow-width: 341px;
  }
}

.carouselRow {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include tablet {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

//.trackDirectionRtl {
//  animation-direction: reverse;
//}
//
//$animationDuration: 40s;

// Animation
//@keyframes scroll {
//  0% {
//    transform: translateX(0);
//  }
//  100% {
//    transform: translateX(calc(var(--gradient-multiplicator) * 250px * 7));
//  }
//}

//.slider {
//  //background: rgba(0, 0, 0, 1);
//  //box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
//  overflow: hidden;
//
//  &:not(:last-child) {
//    margin-bottom: 16px;
//  }
//
//  .slideTrack {
//    animation: scroll $animationDuration linear infinite;
//    animation-direction: normal;
//    display: flex;
//    gap: 24px;
//    overflow: visible;
//    width: calc((104px + 24px) * 34);
//    &.directionRtl {
//      animation-direction: reverse;
//    }
//    &:hover {
//      animation-play-state: paused;
//    }
//  }
//
//  @include tablet {
//    &:not(:last-child) {
//      margin-bottom: 24px;
//    }
//  }
//}
