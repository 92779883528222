@import '@/styles/breakpoints.module';

.stack {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  @include tablet {
    gap: 32px;
  }

  @include desktop {
    gap: 40px;
  }

  & > *:first-child {
    padding: 0 !important;
    box-sizing: border-box !important;
    width: 100%;
  }
}
