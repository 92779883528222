@import 'breakpoints.module';

@mixin h1L_ {
  font-size: 60px;
  line-height: 72px;
  font-weight: var(--font-weight-heading);
}

@mixin h1S_ {
  font-size: 32px;
  line-height: 40px;
  font-weight: var(--font-weight-heading);
}

@mixin h2L_ {
  font-size: 48px;
  line-height: 60px;
  font-weight: var(--font-weight-heading);
}

@mixin h2S_ {
  font-size: 28px;
  line-height: 36px;
  font-weight: var(--font-weight-heading);
}

@mixin h3L_ {
  font-size: 40px;
  line-height: 52px;
  font-weight: var(--font-weight-heading);
}

@mixin h3S_ {
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--font-weight-heading);
}

@mixin h4L_ {
  font-size: 28px;
  line-height: 36px;
  font-weight: var(--font-weight-heading);
}

@mixin h4S_ {
  font-size: 20px;
  line-height: 28px;
  font-weight: var(--font-weight-heading);
}

@mixin h5Tablet_ {
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--font-weight-heading);
}

@mixin h5Mobile_ {
  font-size: 18px;
  line-height: 26px;
  font-weight: var(--font-weight-heading);
}

@mixin h6Tablet_ {
  font-size: 20px;
  line-height: 28px;
  font-weight: var(--font-weight-heading);
}

@mixin h6Mobile_ {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-weight-heading);
}

@mixin subheadingL_ {
  font-size: 22px;
  line-height: 32px;
  //font-weight: 700;
}

@mixin subheadingM_ {
  font-size: 18px;
  line-height: 28px;
}

@mixin subheadingS_ {
  font-size: 16px;
  line-height: 24px;
}

@mixin buttonL_ {
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--font-weight-btn);
}

@mixin buttonM_ {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-weight-btn);
}

@mixin buttonS_ {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-weight-btn);
}

@mixin buttonXS_ {
  // same as buttonS
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-weight-btn);
}

@mixin bodyM_ {
  font-size: 16px;
  line-height: 24px;
}

@mixin bodyS_ {
  font-size: 14px;
  line-height: 20px;
}

@mixin bodyXS_ {
  font-size: 12px;
  line-height: 16px;
}

.h1 {
  color: var(--color-heading);
  @include h1S_;
  @include tablet {
    @include h1L_;
  }
}

.h2 {
  color: var(--color-heading);
  @include h2S_;
  @include tablet {
    @include h2L_;
  }
}

.h3 {
  color: var(--color-heading);
  @include h3S_;
  @include tablet {
    @include h3L_;
  }
}

.h4 {
  color: var(--color-heading);
  @include h4S_;
  @include tablet {
    @include h4L_;
  }
}

.h4S {
  color: var(--color-heading);
  @include h4S_;
}

.h5 {
  color: var(--color-heading);
  @include h5Mobile_;
  @include tablet {
    @include h5Tablet_;
  }
}

.h6 {
  color: var(--color-heading);
  @include h6Mobile_;
  @include tablet {
    @include h6Tablet_;
  }
}

.subheadingL {
  @include subheadingL_;
  color: var(--color-text-secondary);
}

.subheadingM {
  @include subheadingM_;
  color: var(--color-text-secondary);
}

.subheadingS {
  @include subheadingS_;
  color: var(--color-text-secondary);
}

.subheadingML {
  color: var(--color-subtitle);
  @include subheadingM_;
  @include tablet {
    @include subheadingL_;
  }
}

.subheadingSM {
  color: var(--color-subtitle);
  @include subheadingS_;
  @include tablet {
    @include subheadingM_;
  }
}

.subheadingSL {
  color: var(--color-subtitle);
  @include subheadingS_;
  @include tablet {
    @include subheadingL_;
  }
}

@mixin buttonL {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

@mixin buttonM {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.bodyM {
  @include bodyM_;
  color: var(--color-text-secondary);
}

.bodyS {
  @include bodyS_;
  color: var(--color-text-secondary);
}

.bodyXS {
  @include bodyXS_;
  color: var(--color-text-secondary);
}

.regular {
  font-weight: var(--palette-font-weight-regular);
}

.medium {
  font-weight: var(--palette-font-weight-medium);
}

.semibold {
  font-weight: var(--palette-font-weight-semibold);
}

.bold {
  font-weight: var(--palette-font-weight-bold);
}

.headerSubhead {
  margin-top: 12px;
  @include tablet {
    margin-top: 16px;
  }
}
