@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.selectMenu {
  :global .MuiPaper-root {
    :global .MuiList-root:first-child {
      padding: 0;
      color: var(--palette-gray-900);
      @include typography.bodyM_;
    }
  }
}

@mixin icon {
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  svg {
    width: 100%;
    height: auto;
  }
}

.selectButton {
  display: inline-flex;
  padding: 6px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid transparent;
  background: var(--palette-gray-100);
  color: var(--color-text-primary);
  @include typography.bodyM_;

  &.active,
  &:hover {
    border: 1px solid var(--palette-primary-700);
    background: var(--palette-white);
  }
}

.selectIcon {
  @include icon;
  width: 24px;
  height: 24px;
  margin-inline-end: 6px;
}

.chevronDown {
  @include icon;
  width: 18px;
  height: 18px;
  margin-inline-start: 4px;
  color: var(--color-text-primary);

  &.active svg {
    transform: rotateX(180deg);
  }
}

.selectSearchWrapper {
  padding: 12px 12px 8px;
  box-shadow: var(--shadow-cards-light);
}

.emptyResult {
  padding: 10px 20px;
  color: var(--palette-gray-500);
}
