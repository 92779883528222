@import '@/styles/breakpoints.module';

.tickersTape {
  display: flex;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 13%,
    rgba(0, 0, 0, 1) 87%,
    rgba(0, 0, 0, 0) 100%
  );
  position: relative;

  &:hover {
    .row {
      animation-play-state: paused;
    }
  }
}

.row {
  display: flex;
  //border: 1px solid red;
  --gap: 12px;
  margin-inline-end: var(--gap);
  @include tablet {
    --gap: 16px;
  }

  gap: var(--gap);
  animation: slide var(--speed) linear infinite;
}

[dir='rtl'] {
  .tickersTape {
    justify-content: flex-end;
  }
  .row {
    animation: slideRtl var(--speed) linear infinite;
  }
}

.tickersTapeCard {
  font-weight: var(--palette-font-weight-medium);
  white-space: nowrap;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--palette-gray-600);
  background: rgba(39, 39, 39, 0.5);
  backdrop-filter: blur(3px);
  @include tablet {
    padding: 24px;
  }
  &:hover {
    background-color: var(--palette-gray-600);
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes slideRtl {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

.nameAndPrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: var(--palette-font-weight-semibold);
  color: var(--color-text-primary);

  & > :first-child {
    img,
    svg {
      margin-inline-end: 4px;
      max-width: 24px;
      height: auto;
      @include tablet {
        max-width: none;
      }
    }

    display: flex;
    align-items: center;
    margin-inline-end: 35px;
  }

  margin-bottom: 6px;
}

.change {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  @include tablet {
    margin-bottom: 24px;
  }

  svg,
  img {
    margin-inline-end: 4px;
    @include tablet {
      margin-inline-end: 6px;
    }
  }
}

.trend {
  svg {
    width: 100%;
  }
}
