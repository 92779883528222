@import '@/styles/breakpoints.module';

.container {
  box-sizing: border-box;
  width: 100%;
}

.search {
  max-width: 750px;
  margin: 0 auto;
}
