@import '@/styles/breakpoints.module';

.promoCard {
  border-radius: var(--palette-border-radius-s);
  border: 1px solid var(--palette-primary-700);
  background: var(--palette-primary-50);
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: 32px;
  align-items: center;
  justify-items: flex-start;

  & > p:nth-child(4) {
    color: var(--palette-gray-500);
  }

  // not gift/title
  & > :not(:nth-child(1)):not(:nth-child(2)) {
    grid-column: span 2;
  }

  p {
    margin-top: 4px;
  }

  a {
    margin-top: 12px;
  }
}
