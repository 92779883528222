@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.basePaginationItem {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @include typography.buttonS_;

  &:not(:disabled):not(.ellipsis) {
    cursor: pointer;
  }

  &:disabled {
    color: var(--palette-gray-300);
  }

  &:hover:not(.selected):not(:disabled):not(.ellipsis) {
    background-color: var(--palette-primary-100);
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  flex-wrap: wrap;
  @include tablet {
    gap: 4px;
  }

  & > :last-child {
    margin-inline-start: 19.5px;
    @include tablet {
      margin: 0;
    }
  }

  & > :first-child {
    margin-inline-end: 19.5px;
    @include tablet {
      margin: 0;
    }
  }
}

.paginationPrevNextButton {
  display: flex;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  gap: 8px;

  span {
    display: none;

    &:first-child {
      margin-inline-start: 6px;
    }

    &:last-child {
      margin-inline-end: 6px;
    }

    @include tablet {
      display: block;
    }
  }
}

.paginationButton {
  min-width: 40px;
  color: var(--palette-gray-900);
}

.selected {
  color: var(--palette-white);
  background-color: var(--palette-primary-700);
}

.paginationSpaceTopStyles {
  margin-top: 24px;
  @include tablet {
    margin-top: 32px;
  }
  @include desktop {
    margin-top: 40px;
  }
}
