@import '@/styles/breakpoints.module';

.datagridNoBottomRadius.datagridNoBottomRadius.datagridNoBottomRadius {
  @include desktop {
    border-end-start-radius: 0;
    border-end-end-radius: 0;
  }
}

.badge {
  z-index: 100;
  display: none;
  position: absolute;
  background-color: var(--color-table-row-hover-bg);
  max-width: var(--table-overflow-size);
  min-width: var(--table-overflow-size);
  height: 20px;

  &.badgeLeft {
    border-bottom-left-radius: var(--border-radius-table);
    border-top-left-radius: var(--border-radius-table);
  }

  &.badgeRight {
    border-top-right-radius: var(--border-radius-table);
    border-bottom-right-radius: var(--border-radius-table);
  }

  &.visible {
    @include desktop {
      display: block;
    }
  }
}
