.ratingContainer {
  display: flex;
  font-weight: var(--palette-font-weight-bold);
  align-items: center;

  :global .MuiRating-root {
    margin-top: -2px;
  }
}

.ratingAmount.ratingAmount {
  margin-inline-end: 6px;
}

.ratingColorGray {
  color: var(--palette-gray-400);
}

.ratingColorWhite {
  color: var(--palette-white);
}

.ratingColorDark {
  color: var(--palette-dark);
}
