@import '@/styles/breakpoints.module';

.closeButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s var(--store-button-transition-fn);
  mix-blend-mode: multiply;

  &:hover {
    background-color: var(--palette-gray-70);
  }

  &:active {
    background-color: var(--palette-gray-100);
  }

  border-radius: 100%;
}

.closeButtonL {
  width: 40px;
  height: 40px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.closeButtonS {
  width: 24px;
  height: 24px;

  svg {
    width: 16px;
    height: 16px;
  }
}
