.qrWrapper {
  width: 120px;
  border-radius: 4px;
  box-shadow: var(--icon-shadow);
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.noShadow {
  box-shadow: none;
}
