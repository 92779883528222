@import '@/styles/breakpoints.module';

.freePairIcon {
  position: relative;
  display: flex;
  align-items: center;

  img {
    background-color: var(--palette-gray-50);
    border-radius: 100%;

    width: 66%;
    height: auto;
    position: absolute;

    &:first-child {
      inset-inline-start: 0;
      z-index: 1;
    }

    &:last-child {
      inset-inline-start: 34%;
    }
  }
}
