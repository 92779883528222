@use '../../../../../styles/typography.module';

.popper:global(.MuiAutocomplete-popper) {
  &[data-popper-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

  :global .MuiAutocomplete-paper {
    padding: 8px;
    box-shadow: 0 0 30px 0 rgba(11, 11, 11, 0.2);
    border-radius: 8px;

    :global(.MuiAutocomplete-noOptions),
    :global(.MuiAutocomplete-loading) {
      padding: 8px;
      text-align: center;

      svg {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
        color: var(--palette-gray-500);
      }
    }

    :global .MuiAutocomplete-listbox {
      padding: 0;
      max-height: none;

      :global .MuiAutocomplete-option {
        padding: 12px 16px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.autocompleteOptionIcon {
  margin-inline-end: 8px;
  width: 24px;
  height: 24px;
  & > img,
  & > svg {
    width: 100%;
    height: auto;
  }
}

.autocompleteOptionTitleDescription {
  display: flex;
  align-items: center;
}

.autocompleteOptionTitle {
  @include typography.bodyM_;
  color: var(--color-text-primary);
}

.autocompleteOptionDescription {
  @include typography.bodyXS_;
  color: var(--palette-gray-300);
}

.autocompleteOptionLabel {
  margin-inline-start: auto;
  @include typography.bodyXS_;
  color: var(--palette-gray-400);
}
