.placeholder {
  border-radius: var(--border-radius-table);
  overflow: hidden;
  background-color: var(--color-table-stripe-row-bg);
  width: 100%;
  display: flex;
  flex-direction: column;

  :global .MuiSkeleton-root {
    height: var(--height-table-row);
    width: 100%;

    &:nth-last-child(even) {
      background-color: var(--color-table-row-bg);
    }
  }
}
