@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

@mixin icon {
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  svg {
    width: 100%;
    height: auto;
  }
}

.selectMenu {
  :global .MuiPaper-root {
    border-radius: var(--select-menu-border-radius);
    box-shadow: var(--shadow-cards-m);
    margin-top: 4px;

    :global .MuiList-root:first-child {
      padding: 0;
    }
  }
}

.selectItems {
  width: 316px;
  max-height: 300px;
  overflow-y: auto;
  padding-inline-end: 2px;
}

.selectItem:global(.MuiMenuItem-root) {
  padding: 12px 16px;
  padding-inline-start: 12px;

  &:hover {
    background-color: var(--palette-primary-100);
  }

  &:global(.Mui-selected) {
    background-color: var(--palette-primary-700);
    color: var(--palette-white);
  }
}

.selectItemActiveIcon {
  @include icon;
  width: 18px;
  height: 18px;
  margin-inline-end: 4px;
}

.selectItemIcon {
  @include icon;
  width: 24px;
  height: 24px;
  margin-inline-end: 12px;
}
