@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.checkbox.checkbox:global(.MuiCheckbox-root) {
  padding: 0;
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid var(--palette-gray-200);
  background: var(--palette-white);
  color: var(--palette-white);

  &:hover {
    border-color: var(--palette-primary-700);
  }

  &:global(.Mui-focusVisible) {
    border-color: var(--palette-primary-200);
    box-shadow: 0 0 0px 2px var(--palette-primary-100);
  }

  &:global(.Mui-checked) {
    background-color: var(--palette-primary-700);
    border: none;
  }
}

.formControlLabel.formControlLabel:global(.MuiFormControlLabel-root) {
  user-select: none;
  margin: 0;
  align-items: flex-start;

  @include tablet {
    align-items: center;
  }

  :global .MuiCheckbox-root {
    margin-top: 2px;
    @include tablet {
      margin-top: 0;
    }
  }

  :global .MuiFormControlLabel-label {
    @include typography.bodyM_;
    margin-inline-start: 8px;
    color: var(--palette-gray-900);
  }
}
