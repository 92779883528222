@import '@/styles/breakpoints.module';

.dialog {
  & :global .MuiPaper-root {
    border-radius: var(--palette-border-radius-m);
    padding: 16px;
    margin: 16px;
    @include tablet {
      padding: 32px;
    }
  }

  :global .MuiModal-backdrop {
    background-color: var(--overlay-bg-color);
  }

  &.backdropBlurred {
    :global .MuiModal-backdrop {
      backdrop-filter: blur(4px);
    }
  }
}

.modalHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .spacing {
    width: 24px;
  }
}

.closeButton {
  position: absolute;
  inset-inline-end: 10px;
  top: 10px;
  @include tablet {
    inset-inline-end: 15px;
    top: 15px;
  }
}
