@import '@/styles/breakpoints.module';

.cardSignupForAsset {
  display: flex;
  flex-direction: column;
  gap: 16px;
  button {
    width: 100%;
  }
}
