@import '@/styles/breakpoints.module';

.rowLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;

  @include tablet {
    justify-content: flex-end;
  }

  @include desktop {
    gap: 16px;
  }
}
