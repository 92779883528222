@import '@/styles/breakpoints.module';

.sliderControlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--vstack-spacing-sm);
  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }
  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }

  button {
    cursor: pointer;
  }
}

.sliderPaginationContainer {
  margin: 0 16px;

  li {
    &:not(:last-child) {
      margin-inline-end: 8px;
    }

    button {
      transition: all 200ms ease-in-out;

      height: 12px;
      width: 12px;
      background-color: var(--slider-pagination-bullet-color);
      border-radius: 100%;
      opacity: var(--slider-pagination-bullet-opacity);

      &[class*='active'] {
        background-color: var(--slider-pagination-bullet-active-color);
        width: 41px;
        border-radius: 24px;
        opacity: var(--slider-pagination-bullet-active-opacity);
      }
    }
  }
}
